<template>
  <div>
    <heads></heads>
    <div :class="[
      $route.meta.id == 10 ? ' cen' : ' ',
      $route.meta.id == 21 ? ' cent' : '',
      $route.meta.id == 17 ? ' cens' : '',
    ]" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="cen_box">
        <div :class="[
          $route.meta.id == 17 ? 'cen-topst' : 'cen-top',
          $route.meta.id == 21 ? ' anst' : ' ',
        ]">
          <div class="link-wrap" v-for="(item, index) in videoList" :key="index">
            <router-link :to="{
              path: pathUrl,
              query: { id: item.id },
            }" class="i-item">
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getVideoList } from "@api/public";
export default {
  components: {
    heads,
    foot,
  },
  props: {},
  data() {
    return {
      videoList: [],
      backgroud: "",
      pathUrl: "",
    };
  },
  mounted: function () {
    let that = this;
    getVideoList({ course_cate_id: this.$route.meta.id, limit: 30 }).then(function (res) {
      that.$set(that, "videoList", res.data.list);
    });
  },
  created: function () {
    document.title = this.$route.meta.title;
    this.pathUrl = this.$route.meta.path;
    this.backgroud = this.$route.meta.background;
  },
  methods: {},
};
</script>
<style scoped>
.cen {
  width: 100%;
  min-width: 1484px;
  height: 1095px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.cens {
  width: 100%;
  min-width: 1484px;
  height: 1195px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.cent {
  width: 100%;
  min-width: 1484px;
  height: 1195px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.cen_box {
  width: 1484px;
  margin: 0 auto;
  position: relative;
}

.cen-top {
  position: absolute;
  width: 370px;
  height: 420px;
  top: 308px;
  left: 685px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row-reverse;
}

.cen-tops {
  width: 370px;
  height: 420px;
  position: absolute;
  top: 308px;
  left: 485px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cen-top.duomei {
  top: 325px;
  width: 650px;
  left: 460px;
  justify-content: flex-start;
}

.cen-top.anst {
  /* top: 1475px; */
  width: 650px;
  left: 460px;
  justify-content: flex-end;
}

.link-wrap {
  width: 30px;
  margin: 0 17px;
}

.i-item {
  display: inline-block;
  width: 30px;
  color: #000;
  text-decoration: none;
  font-size: 22px;
  font-family: "楷体";
  font-weight: bold;
  writing-mode: vertical-lr;
}

.i-item:hover {
  color: #e2cea0;
}

.cen-topst {
  display: none;
}
</style>